import './scss/main.scss';

import 'bootstrap/js/dist/collapse';

import 'slick-carousel';

// NAVBAR
const $navbar = document.getElementById('navbar');

window.onscroll = () => {
  const scrollTop = window.pageYOffset
                    || document.documentElement.scrollTop
                    || document.body.scrollTop
                    || 0;

  if (scrollTop > 0) {
    $navbar.classList.add('is-affix');
    $('.home-recipes').addClass('is-active');
  } else {
    $navbar.classList.remove('is-affix');
  }
};

$('#navbar-content').on('show.bs.collapse hide.bs.collapse', () => {
  $('#navbar').toggleClass('is-open');
});

$('.js-slick').slick({
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: false,
  prevArrow: '#slick-prev',
  nextArrow: '#slick-next',
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
});
